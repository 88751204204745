import React from 'react'
import Logo from "../../assets/img/Egold_logo.svg"
import "./header.css"
import { useNavigate, useLocation } from "react-router-dom";
export default function Header() {
    let history = useNavigate();
    return (
        <div className='container' style={{ maxWidth: 1530 }}>


            <div id="header" class=" d-flex align-items-center">
                <div class="container d-flex align-items-center headericon">

                    <div class="logo" onClick={() => {
                        history("/");
                    }}>
                        <h1 class="text-light"><a > <img src={Logo} /></a></h1>
                    </div>

                    <nav id="navbar" class="navbar">
                        <ul>

                        
                    <a
            onClick={() => {
              history("/");
            }}
            class=" py-2 px-2 inline text-black rounded hover:text-[#856C39] md:p-0 cursor-pointer"
          >
            EN
          </a>{"|"}
          <a
            href="https://cn.egoldminer.shop/"
            class=" py-2 px-2 inline rounded md:p-0 cntext"
          >
             中国
          </a>{" "}
          </ul>
                        <ul>
                            <li><a class="nav-link scrollto active" href="#home">Miners Available</a></li>
                            <li><a class="nav-link scrollto" href="#anft">ANFT Technology</a></li>
                            <li><a class="nav-link scrollto" href="#how-it-work">How it work</a></li>
                            <li><a class="nav-link scrollto" href="#about">About</a></li>
                            <li><a class="nav-link scrollto" href="https://egold.gitbook.io/egold/" target="_blank">Gitbook</a></li>
                        </ul>
                        <i class="bi bi-list mobile-nav-toggle"></i>
                    </nav>
                    
                </div>
            </div>
        </div>
    )
}
