import React, { useEffect, useState } from 'react';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import Web3 from 'web3';
import LoginButtons from '../Login/LoginButtons';
import Miner from './miners';
import MinerCard from './RandomMiners';
import './index.css';
import '../Login/login.css';



export default function MinerDetails() {
    const location = useLocation();
    const history = useNavigate()


    const miners = [21, 22, 23, 24, 25, 26, 27, 28];
    const minersType = {
        S1: 5,
        S2: 6,
        S3: 7,
        S4: 8,
        S5: 9,
        // G1: 1,
        G2: 2,
        G3: 3,
        G4: 4,
    };

    const dailySupply = 6849.315068;
    var currentMiner = ""
    const [randomMiners, setrandomMiners] = useState([]);
    const [netHP, setNetHP] = useState('...');
    const [egPrice, setEgPrice] = useState('...');
    const [calculated, setCalculated] = useState('...');
    const [customProp, setcustomProp] = useState(location.state);

    useEffect(() => {

        let randMiners = getRandomMiners(currentMiner);
        setrandomMiners(randMiners);
        getSummary();

    }, []);

    useEffect(() => {

        setcustomProp(location.state)
    }, [location.state]);

    useEffect(() => {
        console.log("custom pros", customProp)
        if (customProp != null) {
            currentMiner = minersType[customProp.props.data.type];
        }

    }, [customProp]);

    useEffect(() => {
        if (customProp != null) {
            let randMiners = getRandomMiners(currentMiner);
            setrandomMiners(randMiners);
        }
    }, [customProp]);

    useEffect(() => {
        if (customProp != null) {
            setCalculated(parseFloat((customProp.props.data.power / netHP) * dailySupply));
        }
    }, [customProp, netHP]);

    function getRandomMiners(notMiner) {
        const filteredArray = miners.filter((item) => item !== notMiner);
        if (filteredArray.length < 4) {
            return null; // Not enough available values
        }
        const randomIndices = [];
        while (randomIndices.length < 4) {
            const randomIndex = Math.floor(Math.random() * filteredArray.length);
            if (!randomIndices.includes(randomIndex)) {
                randomIndices.push(randomIndex);
            }
        }
        return [
            filteredArray[randomIndices[0]],
            filteredArray[randomIndices[1]],
            filteredArray[randomIndices[2]],
            filteredArray[randomIndices[3]],
        ];
    }

    const getSummary = async () => {
        const url = 'https://egold-treasury.tagdev.info/v1/summary';
        fetch(url)
            .then((response) => response.json())
            .then((newData) => {
                console.log('---', newData);
                setNetHP(newData.hashPowerSold);
            })
            .catch((error) => {
                console.log('Request failed', error);
            });

        const urltd = 'https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/20/';
        // fetch 24-hour market data
        fetch(urltd)
            .then((response) => response.json())
            .then((newData) => {
                setEgPrice(parseFloat(newData[0].TAG_USD).toFixed(4));
            })
            .catch((error) => {
                console.log(error);
            });
    };

    function noround(number, decimalDigits) {
        const powerOfTen = Math.pow(10, decimalDigits);
        const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
        if (Number.isInteger(formattedNumber)) {
            return roundzeroes(formattedNumber, 2);
        } else {
            return formattedNumber;
        }
    }

    function roundzeroes(val, x) {
        var float = parseFloat(val).toFixed(18);
        var num = float.toString();
        var n = num.slice(0, num.indexOf('.') + (x + 1));
        return n;
    }

    return (

        customProp != undefined ?
            <div>
                <main>
                    <div className="container">
                        <div className="backbtnsec mb20" style={{ position: 'relative' }}>
                            <a className="backbtn" href="/">
                                <IoArrowBackCircleOutline color="#4F6B75" className="backicon" />
                                <span className="spback">Back</span>{' '}
                            </a>
                            <div className="backheadmed">Miner Details</div>
                            <div></div>
                        </div>
                    </div>
                    <div className="container mb20 mdcontainer" id="home">
                        <div className="row dimgrey">
                            <div className="col-md-5">
                                <Miner
                                    data={{
                                        type: customProp.props.data.type,
                                        power: customProp.props.data.power,
                                        price: customProp.props.data.price,
                                        bg: 'white',
                                        img: customProp.props.data.img,
                                        new: customProp.props.data.new,
                                    }}
                                />
                                <div className="loginmdetails">
                                    <div style={{ padding: 20 }}>
                                        <LoginButtons />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="bg-white logsignsec">
                                    <p className="headingtext2">Miner Profitability (1st claim)</p>
                                    <div className="table-responsive">
                                        <table className="table table-bordered minerspectable">
                                            <thead>
                                                <tr>
                                                    <th className="lightblue-cell-bg"></th>
                                                    <th className="text-center lightblue-cell-bg">1 day <span>(Egold)</span></th>
                                                    <th className="text-center lightblue-cell-bg">30 days <span>(Egold)</span></th>
                                                    <th className="text-center lightblue-cell-bg">365 days <span>(Egold)</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Production</td>
                                                    <td className="text-center fw700">
                                                        {isNaN(calculated) ? "..." : noround(parseFloat(calculated) * 1, 4)}
                                                    </td>
                                                    <td className="text-center fw700">
                                                        {isNaN(calculated) ? "..." : noround(calculated * 30, 4)}
                                                    </td>
                                                    <td className="text-center fw700">
                                                        {isNaN(calculated) ? "..." : noround(calculated * 365, 4)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Mining Fuel</td>
                                                    <td className="text-center red fw700">
                                                        {isNaN(calculated) ? "..." : "- " + noround(0.5 * calculated * 1, 4)}
                                                    </td>
                                                    <td className="text-center red fw700">
                                                        {isNaN(calculated) ? "..." : "- " + noround(0.5 * calculated * 30, 4)}
                                                    </td>
                                                    <td className="text-center red fw700">
                                                        {isNaN(calculated) ? "..." : "- " + noround(0.5 * calculated * 365, 4)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Depreciation</td>
                                                    <td className="text-center red fw700">
                                                        {isNaN(calculated) ? "..." : "- 0.0000"}
                                                    </td>
                                                    <td className="text-center red fw700">
                                                        {isNaN(calculated) ? "..." : "- 0.0000"}
                                                    </td>
                                                    <td className="text-center red fw700">
                                                        {isNaN(calculated) ? "..." : "- 0.0000"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Hosting</td>
                                                    <td className="text-center red fw700">
                                                        {isNaN(calculated) ? "..." : "- " + noround(0.01 * calculated * 1, 4)}
                                                    </td>
                                                    <td className="text-center red fw700">
                                                        {isNaN(calculated) ? "..." : "- " + noround(0.01 * calculated * 30, 4)}
                                                    </td>
                                                    <td className="text-center red fw700">
                                                        {isNaN(calculated) ? "..." : "- " + noround(0.01 * calculated * 365, 4)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Est. Yield</td>
                                                    <td className="text-center fw700">
                                                        {isNaN(calculated) ? "..." : noround(0.49 * calculated * 1, 4)}
                                                    </td>
                                                    <td className="text-center fw700">
                                                        {isNaN(calculated) ? "..." : noround(0.49 * calculated * 30, 4)}
                                                    </td>
                                                    <td className="text-center fw700">
                                                        {isNaN(calculated) ? "..." : noround(0.49 * calculated * 365, 4)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Est. Profit</td>
                                                    <td className="text-center green fw700">
                                                        ${egPrice === "..." || isNaN(calculated)
                                                            ? "..."
                                                            : noround(egPrice * 0.49 * calculated * 1, 4)}
                                                    </td>
                                                    <td className="text-center green fw700">
                                                        ${egPrice === "..." || isNaN(calculated)
                                                            ? "..."
                                                            : noround(egPrice * 0.49 * calculated * 30, 4)}
                                                    </td>
                                                    <td className="text-center green fw700">
                                                        ${egPrice === "..." || isNaN(calculated)
                                                            ? "..."
                                                            : noround(
                                                                egPrice * 0.49 * (customProp.props.data.power / netHP) * dailySupply * 365,
                                                                4
                                                            )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p className="headingtext2">Miner Specifications</p>
                                    <div className="table-responsive">
                                        <table className="table table-bordered minerspectable">
                                            <tbody>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Miner Type</td>
                                                    <td className="text-center fw700">Egold Miner {customProp.props.data.type}</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Mining power</td>
                                                    <td className="text-center fw700">{customProp.props.data.power} MHS</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Max Claims</td>
                                                    <td className="text-center fw700">10</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Mining Period</td>
                                                    <td className="text-center fw700">Unlimited</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Depreciation</td>
                                                    <td className="text-center fw700">5% Per Claim</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Hosting Fee</td>
                                                    <td className="text-center fw700">1%</td>
                                                </tr>
                                                <tr>
                                                    <td className="text-center lightblue-cell-bg">Price</td>
                                                    <td className="text-center fw700 green">${customProp.props.data.price}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <section id="about" className="statistics lightblue-bg othersections">
                        <div className="container" data-aos="fade-up">
                            <div className="row justify-content-between">
                                <div className="col-lg-12">
                                    <p className="headingtext2 mb20 othermins">Other miners</p>
                                    <div className="row">
                                        {randomMiners.length > 0 ? (
                                            randomMiners.map((item, index) => (
                                                <div className="col-md-3 col-sm-12" key={index}>
                                                    <MinerCard type={item} />
                                                </div>
                                            ))
                                        ) : (
                                            <div className="col-md-3 col-sm-12">
                                                <Spinner />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </div> : history("/")


    );
}
