import React from 'react'
import Header from "./components/Header/Header"
import Loginsection from './components/Login/Loginsection';
import Middlesec from "./components/Middlesections/index"
import Miners from "./components/Miners/index"
import Static from "./components/Static/index"

export default function App() {
    return (
        <div><Header />
            <Loginsection />
            <Middlesec />
            <Miners />
            <Static />
        </div>
    )
}
