import React from 'react'
import "./index.css"
import NFT from "../../assets/img/nft.svg"
export default function index() {
    return (
        <div>
            <section id="anft" class="anft section-bg">
                <div class="container">
                    <div class="row justify-content-between">

                        <div class="col-lg-12">
                            <h3 data-aos="fade-up" class="text-center anfth3" >ANFT Technology</h3>
                            <p data-aos="fade-up" data-aos-delay="100" class=" text-justify anftp">
                                ANFT's are simply NFTs that have programming or business logic coded into them by default. Regular NFT's have only images and files attached, whereas an ANFT has a smart contract with business logic attached to it.
                            </p>
                            <span class="text-center d-block" data-aos="fade-up" data-aos-delay="300"><img src={NFT} height={105} alt="" /></span>
                        </div>
                    </div>

                </div>
            </section>
            {/* <!-- ======= how-it-work ======= --> */}
            <section id="how-it-work" class="clients section-bg">
                <div class="container" data-aos="fade-up">

                    <div class="section-title">
                        <h3 style={{ fontSize: 36, color: "#253237", fontWeight: '600' }}>How it work</h3>
                    </div>

                    <div class="how-works d-flex flex-row d-flex align-items-center">
                        <p class="">Buy Egold Miner</p>
                        <span class="">1</span>
                    </div>
                    <div class="how-works d-flex flex-row d-flex align-items-center">
                        <p class="">Go to <a href="https://www.egoldproject.com/" style={{ color: "#FFBD02" }} target="_blank">egoldproject.com</a></p>
                        <span class="">2</span>
                    </div>
                    <div class="how-works d-flex flex-row d-flex align-items-center">
                        <p class="">Use the same wallet you used for the
                            purchase to connect to the app.</p>
                        <span class="">3</span>
                    </div>
                    <div class="how-works d-flex flex-row d-flex align-items-center">
                        <p class="">Stake Egold Miner</p>
                        <span class="">4</span>
                    </div>

                    <div class="how-works d-flex flex-row d-flex align-items-center">
                        <p class="">Unstake egold Miner</p>
                        <span class="">5</span>
                    </div>

                    <div class="how-works d-flex flex-row d-flex align-items-center">
                        <p class="">Pay Mining Fuel & Depreciation Fee</p>
                        <span class="">6</span>
                    </div>

                    <div class="how-works d-flex flex-row d-flex align-items-center">
                        <p class="">Collect Egold</p>
                        <span class="">7</span>
                    </div>

                </div>
            </section>

            {/* //ABOUT SECTION */}
            <section id="about" class="statistics section-bg">
                <div class="container" data-aos="fade-up">

                    <div class="row justify-content-between">

                        <div class="col-lg-12">
                            <h3 data-aos="fade-up" class="text-center abouth3">About</h3>
                            <p data-aos="fade-up" data-aos-delay="100" class="text-justify aboutpdesc">
                                Egold project is an initiative that aims to start a debate around crypto Tokenomics in the same breathe as advancement in blockchain technology. At egold we are building new tools for miners, traders and crypto users in general. We are also utilizing the burn feature in blockchain to construct best deflationary Tokenomics.
                            </p><br />
                            <span class="text-center d-block aboutp" data-aos="fade-up" data-aos-delay="300" style={{ fontSize: 26, fontWeight: '400' }}>For more infomation, please visit <a href="https://www.egoldproject.com/" style={{ color: "#FFBD02", fontWeight: '600', }} target="_blank">www.egoldproject.com</a></span>
                        </div>
                    </div>

                </div>
            </section>

        </div>

    )
}
