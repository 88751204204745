import React from 'react'
import Header from "../Header/Header"
import MinerDetails from './minerDetails'
import "./index.css"

export default function Trial() {
    return (
        <>

            <div className='dimgrey ' style={{ flex: 1 }}>
                <Header />
                <MinerDetails />

                {/* <div className='mdfooter'> */}

                {/* </div> */}

            </div >

        </>
    )
}
