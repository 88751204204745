import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataVal from "../data/Abis.json";
import { Spinner } from "reactstrap";
export default function MinerCard(props) {
  let navigate = useNavigate();
  console.log("indo pros", props);
  const [minerName, setMinerName] = useState("...");
  const [minerPrice, setMinerPrice] = useState("...");
  const [minerHash, setMinerHash] = useState("...");

  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  useEffect(() => {
    getMinerInfo();
  }, []);

  useEffect(() => {
    getMinerInfo();
  }, [props.type]);

  const getMinerInfo = async () => {
    const contractInstance = new web3.eth.Contract(
      dataVal.mregistryabi,
      process.env.REACT_APP_MINER_REGISTRY
    );

    await contractInstance.methods
      .fetchMinerInfo(props.type)
      .call()
      .then((res) => {
        console.log("resshere", res);
        setMinerName(res.name);
        setMinerPrice(parseInt(res.minerBaseRate));
        setMinerHash(parseInt(res.hashRate));
      });
  };

  const oldPrice = {
    22: 25,
    41: 50,
    90: 100,
    441: 490,
    864: 960,
    2115: 2350,
    4140: 4600,
    8100: 9000,
  };
  
  return (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/minerdetails", {
            state: {
              props: {
                data: {
                  type: minerName,
                  power: minerHash,
                  price: minerPrice,
                  bg: "grey",
                  img: require("../../assets/img/minera/" + minerName + ".png"),
                  new: minerName.charAt(0) == "S" ? true : false,
                },
              },
            },
          });
        }}
      >
        <div class="minerAvailableCard bg-white">
          <div class="minerAvailableTitle">
            EGOLD Miner <span class="">{minerName}</span>
          </div>
          {minerName !== "..." ? (
            <div
              style={
                minerName.charAt(0) == "X"
                  ? { display: "flex", justifyContent: "center" }
                  : { visibility: "hidden" }
              }
            >
              <p className="newedition">New Edition</p>
            </div>
          ) : null}
          <div class="minerAvailableImage">
            {minerName == "..." ? (
              <div style={{ padding: "50px" }}>
                <Spinner />
              </div>
            ) : (
              <img
                src={require("../../assets/img/minera/" + minerName + ".png")}
                class="img-fluid"
              />
            )}
          </div>

          <a class="minerAvailablebtn">Power : {minerHash} mhs</a>
          <a
            class="minerAvailablebtn"
            style={{ gap: "5px", alignItems: "baseline" }}
          >
            Price : <span style={{ fontWeight: "500" }}>${minerPrice}</span>{" "}
            {oldPrice[minerPrice] ? (
              <div>
                <span
                  style={{
                    textDecoration: "line-through",
                    fontSize: "12px",
                    color: "#fb3131",
                  }}
                >
                  {" "}
                  ${oldPrice[minerPrice]}
                </span>
              </div>
            ) : (
              ""
            )}
          </a>
        </div>
      </div>
    </>
  );
}
