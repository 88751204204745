import React, { useEffect, useState } from 'react'
import Calculator from "../../assets/img/calculator.svg"
import "./index.css"
import { useNavigate } from 'react-router-dom';
export default function Index() {
    const history = useNavigate();
    const [minerdata, minerData] = useState("...");

    useEffect(() => {
        getSummary();
    }, []);

    async function getSummary() {
        const minersData = "https://egold-treasury.tagdev.info/v1/summary";
        fetch(minersData)
            .then(function (response) {
                return response.json();
            })
            .then(function (newData) {
                minerData(newData);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function numFormatter(num) {
        if (num > 999 && num < 1000000) {
            return noround(num / 1000, 2) + " K"; // convert to K for number from > 1000 < 1 million
        } else if (num > 1000000) {
            return noround(num / 1000000, 2) + " M"; // convert to M for number from > 1 million
        } else if (num < 1000) {
            return num; // if value < 1000, nothing to do
        }
    }
    function noround(val, x) {
        if (!val) return 0;
        var float = parseFloat(val).toFixed(18);
        var num = float.toString();
        var n = num.slice(0, num.indexOf(".") + (x + 1));
        return n;
    }
    return (
        <div class="container" data-aos="fade-up">
            <section id="calculator" class="calculator-bg midsecpad">

                <div class="row" style={{ justifyContent: "center" }}>
                    <div class="col-md-3">  <div class="calcell cellmid griddis gapdis mt-0">{minerdata == "..." ? "..." : minerdata.minersSold}<span style={{ fontWeight: '400' }}>MINERS</span></div></div>
                    <div class="col-md-1">

                    </div>

                    <div class="col-md-3 hashratemid">  <div class="calcell cellmid griddis">{minerdata == "..." ? "..." : numFormatter(minerdata.hashPowerSold)}<span style={{ fontWeight: '400' }}>NETWORK HASHRATE<br />
                        (META HASHES)</span></div> </div>

                    <div class="col-md-1">

                    </div>

                    <div class="col-md-3 calcpos">  <div className='miningcalc'><a class="calculatorBtn scrollto rescalc" onClick={() => {
                        history("/minercalculator")
                    }} style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}>
                        <img src={Calculator} class="img-fluid" alt="" /><p style={{ margin: 0 }}>Mining Calculator</p>
                    </a>
                    </div>
                    </div>



                </div>
            </section>
        </div>
    )
}


