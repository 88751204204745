import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Header from "./components/Header/Header"
import MinerDetails from "./components/Miners/minerDetails"
import App from './App';
import Footer from "./components/Footer/index"
import MinerWrap from './components/Miners/MinerWrap';
import { useRouteError } from "react-router-dom";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Web3ReactProvider } from "@web3-react/core";
import { hooks as metaMaskHooks, metaMask } from "./connectors/metaMask";
import {
  coinbaseWallet,
  hooks as coinbaseWalletHooks,
} from "./connectors/coinbaseWallet";
import {
  hooks as walletConnectV2Hooks,
  walletConnectV2,
} from "./connectors/walletConnect";
import MiningCalc from "./components/Calculator/calculator"
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <App />
        </div>
        <Footer />
      </>
    )
  },
  {
    path: "/minerdetails",
    element: (
      <>

        <div style={{ flex: "1 1" }}>
          <MinerWrap />
        </div>
        <Footer />
      </>
    )
  },
  {
    path: "/minercalculator",
    element: (
      <>
        <div style={{ flex: "1 1" }}>
          <Header />
          <MiningCalc />
        </div>
        <Footer />


      </>
    )
  },
])

const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks],
];

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <Web3ReactProvider connectors={connectors}>
      <div className="content">
        <div className="wrapper">
          <RouterProvider router={router} />
        </div>
      </div>
    </Web3ReactProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
