import React from 'react'
import { useNavigate } from 'react-router-dom';
import IMG from "../../assets/img/header_img.png"
import "./login.css"
import LoginButtons from './LoginButtons'
export default function Loginsection() {

    const history = useNavigate();
    return (
        <section id="home" class="d-flex align-items-center">
            <div class="container">
                <div className='moblang'>                     
                <a
            onClick={() => {
              history("/");
            }}
            class=" py-2 px-2 inline text-black rounded hover:text-[#856C39] md:p-0 cursor-pointer"
          >
            EN
          </a>{"|"}
          <a
            href="https://cn.egoldminer.shop/"
            class=" py-2 px-2 inline text-[#00000073] rounded hover:text-[#856C39] md:p-0 cntext"
            
          >
             中国
          </a>{" "}
                </div>
                <div class="row gy-4">
                    <div class="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
                        <h2 style={{
                            color: "#002A40",
                            fontWeight: '500'
                        }}>Egold ANFT Miners</h2>
                        <h3>Join the fastest-growing crypto mining network in the world and start collecting Egold instantly.</h3>
                        <div className="loginbtnss">
                            <LoginButtons />
                        </div>

                    </div>
                    <div class="col-lg-6 order-1 order-lg-2 hero-img">
                        {/* <img src={IMG} class="img-fluid" alt="" /> */}
                        <iframe
                            src="https://player.vimeo.com/video/815432956?h=d82f5a98c6&autoplay=1&loop=1&muted=1&backgound=1&controls=false"
                            style={{
                                // position: "absolute",
                                // top: 0,
                                // left: 0,
                                margin: 0,
                                width: "100%",
                                height: "100%",
                            }}
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>

        </section>
    )
}
