import React from 'react'
import website from "../../assets/img/footer/project_website.png"
import calculator from "../../assets/img/footer/calculator.png"
import youtube from "../../assets/img/footer/youtube.png"
import twitter from "../../assets/img/footer/twitter.png"
import telegram from "../../assets/img/footer/telegram.png"
import gitbook from "../../assets/img/footer/gitbook.png"
import "./index.css"
import { AiFillCalculator } from "react-icons/ai";
import { FaCircleInfo } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
export default function Index() {
    const navigate = useNavigate();
    return (
        // <div><footer id="footer">
        //     <div class="container" >
        //         <div class="row">
        //             <div class="col-lg-12 padzero">
        //                 <div class="row">
        //                     <div class="widthfooter respmb setleft setgrid">
        //                         <div class="footerlist mb-3"><div class="footerimg"><img src={website} class="img-fluid" /></div>Project Website</div>
        //                         <div class="footerlist "><div class="footericn"><AiFillCalculator size={20} color="#FFC726" /></div>Mining Calculator</div>
        //                     </div>
        //                     <div class="widthfooter respmb setright setgrid">
        //                         <div class="footerlist mb-3"><div class="footericn"><FaCircleInfo size={20} color='#FFC726' /></div>Project Information</div>
        //                         <div class="footerlist"><div class="footerimg"><img src={website} class="img-fluid" /></div>Egold Statistics</div>
        //                     </div>
        //                 </div>
        //             </div>

        //         </div>

        //         {/* <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a> */}
        //     </div>
        // </footer>
        //     <div id="bottomFooter">
        //         <div style={{ background: "#3A4E56", padding: 10 }}>
        //             <div class="col-lg-12 col-md-12 col-sm-12 social-links">
        //                 <a href="#" class="youtube" target="_blank"><img src={youtube} alt="" /></a>
        //                 <a href="https://twitter.com/egoldproject" class="twitter" target="_blank"><img src={twitter} alt="" /></a>
        //                 <a href="https://t.me/egold_farm" class="telegram" target="_blank"><img src={telegram} alt="" /></a>
        //                 <a href="https://t.me/egold_farm" class="telegram" target="_blank"><img src={gitbook} alt="" /></a>

        //             </div>
        //         </div>

        //         <p class="text-center bottomFooterp">&copy; Copyright EGOLD Project 2023</p>

        //     </div>

        // </div>
        <div className="footerdiv">
            <footer id="footer">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 respmb">
                                    <a
                                        href="https://www.egoldproject.com/"
                                        target="_blank"
                                        style={{ color: "unset" }}
                                    >
                                        <div class="footerlist mb-3">
                                            <div class="footerimg">
                                                <img src={website} class="img-fluid" />
                                            </div>
                                            Project Website
                                        </div>
                                    </a>
                                    <div
                                        class="footerlist"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            navigate("/minercalculator");
                                        }}
                                    >
                                        <div class="footerimg">
                                            {/* <img src={calc} class="img-fluid" /> */}
                                            <AiFillCalculator size={20} color="#FFC726" />
                                        </div>
                                        Mining Calculator
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 ">
                                    <a
                                        href="https://egold.gitbook.io/egold/"
                                        target="_blank"
                                        style={{ color: "unset" }}
                                    >
                                        <div class="footerlist mb-3">
                                            <div class="footerimg">
                                                {/* <img src={info} class="img-fluid" /> */}
                                                <FaCircleInfo size={20} color='#FFC726' />
                                            </div>
                                            Project Information
                                        </div>
                                    </a>
                                    <a
                                        href="https://www.egoldstats.io/"
                                        target="_blank"
                                        style={{ color: "unset" }}
                                    >
                                        <div class="footerlist">
                                            <div class="footerimg">
                                                <img src={website} class="img-fluid" />
                                            </div>
                                            Egold Statistics
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 social-links">
                            {/* <a href="#" class="youtube" target="_blank">
                <img src={youtube} alt="" class="footersocialicon" />
              </a> */}
                            <a
                                href="https://twitter.com/egoldproject"
                                class="twitter"
                                target="_blank"
                            >
                                <img src={twitter} alt="" class="footersocialicon" />
                            </a>
                            <a
                                href="https://t.me/egold_farm"
                                class="telegram"
                                target="_blank"
                            >
                                <img src={telegram} alt="" class="footersocialicon" />
                            </a>
                            <a
                                href="https://egold.gitbook.io/egold/"
                                class="telegram"
                                target="_blank"
                            >
                                <img src={gitbook} alt="" class="footersocialicon" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
            <div id="bottomFooter">
                <p class="text-center bottomFooterp">
                    &copy; Copyright EGOLD Project 2023
                </p>
            </div>
        </div>
    )
}
