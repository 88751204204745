import React from "react";
import { useNavigate } from "react-router-dom";
export default function Miners(props) {
  const history = useNavigate();

  const oldPrice = {
    22: 25,
    41: 50,
    90: 100,
    441: 490,
    864: 960,
    2115: 2350,
    4140: 4600,
    8100: 9000,
  };

  return (
    <div
      class={
        props.data.bg == "grey"
          ? "minerAvailableCard"
          : "minerAvailableCardWhite"
      }
      style={{
        cursor: "pointer",
      }}
      onClick={() => {
        history("/minerdetails", { state: { props } });
      }}
    >
      <div class={"minerAvailableTitle"}>
        EGOLD Miner <span class="">{props.data.type}</span>
      </div>
      {props.data.new == true ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="newedition">New Edition</p>
        </div>
      ) : null}

      <div
        class="minerAvailableImage"
        style={props.data.new == true ? {} : { marginTop: 45 }}
      >
        <img src={props.data.img} class="img-fluid" />
      </div>

      <a class="minerAvailablebtn">Power : {props.data.power} mhs</a>
      <a
        class="minerAvailablebtn"
        style={{ gap: "5px", alignItems: "baseline" }}
      >
        Price :{" "}
        <p
          style={{
            margin: 0,
            paddingLeft: 5,
            fontSize: 18,
            fontWeight: "500",
            color: "#253237",
            display: "flex",
            gap: "5px",
          }}
        >
          ${props.data.price}
          {oldPrice[props.data.price] ? (
            <div>
              <span
                style={{
                  textDecoration: "line-through",
                  fontSize: "13px",
                  color: "#fb3131",
                }}
              >
                {" "}
                ${oldPrice[props.data.price]}
              </span>
            </div>
          ) : (
            ""
          )}
        </p>
      </a>
    </div>
  );
}
